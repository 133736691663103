import React from "react";
import tw, { css } from "twin.macro";

interface Props {
  icon?: React.ReactNode;
  header?: number | string;
}

const styles = css`
  ${tw`justify-center items-start bg-white rounded-xl border-2 shadow-lg my-2 mx-2 px-4 py-8`}
  > h3 {
    ${tw`rounded-full mx-auto h-24 w-24 flex items-center justify-center text-center text-turquoise-dark text-4xl sm:text-5xl font-black bg-turquoise-light`}
  }
  > div {
    ${tw`mx-auto flex items-center justify-center text-center h-32 w-32`}
  }
`;

const Card: React.FC<Props> = ({ header, children, icon, ...rest }) => {
  const content = icon
    ? css`
        ${tw`mt-6 mb-10 mx-auto text-center max-w-prose text-xl`}
      `
    : css`
        ${tw`text-center text-xl sm:text-2xl my-10 sm:my-16 mx-auto sm:mx-1`}
      `;
  return (
    <div css={styles} {...rest}>
      {icon && <div>{icon}</div>}
      {header && <h3>{header}</h3>}
      <p css={content}>{children}</p>
    </div>
  );
};

export default Card;
