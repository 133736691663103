import React from "react";
import { Trans, useTranslation } from "react-i18next";
import tw, { css } from "twin.macro";

import { BookMeeting } from "../../components/BookMeeting";
import Card from "../../components/Card";
import { Container } from "../../components/Container";
import { getTranslationList } from "../../utils/common";

const doubleGuarantee = css`
      ${tw`my-8 justify-center items-start`}
      > div {
          > div {
          ${tw`mx-8 shadow-lg border-2 px-4 py-8 bg-white rounded-xl`}
          > p {
              ${tw`text-center mx-auto text-2xl my-16`}
          }
          > div {
              ${tw`rounded-full mx-auto h-24 w-24
              flex items-center justify-center text-center text-turquoise-dark text-5xl font-black bg-turquoise-light`}
          }
      }
  `;

const DoubleGuarantee: React.FC = ({ ...rest }) => {
  const { t } = useTranslation();

  const guaranteeList = getTranslationList(t, "index.doubleGuarantee.list");

  return (
    <Container {...rest}>
      <section>
        <article css={doubleGuarantee}>
          <span tw="text-xl">{t("index.doubleGuarantee.weGotYourBack")}</span>
          <h2>
            <Trans i18nKey="index.doubleGuarantee.header" components={{ br: <br /> }} />
          </h2>
          <p tw="text-xl">{t("index.doubleGuarantee.weUnderstand")}</p>
          <p tw="text-xl">{t("index.doubleGuarantee.ourPromise")}</p>
          <div tw="grid grid-cols-1 sm:grid-cols-2 mt-10">
            {guaranteeList.map((item, i) => (
              <Card header={i + 1} key={i}>
                <Trans i18nKey={t(item)} components={{ bold: <strong />, br: <br /> }} />
              </Card>
            ))}
          </div>
          <BookMeeting />
        </article>
      </section>
    </Container>
  );
};

export default DoubleGuarantee;
